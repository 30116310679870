
.rectangle {
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5%;
  padding-bottom: 1%;
  padding-top: 1%;
  padding-left: 3%;
}

.iconGME {
  width: 115px;
  overflow: visible;
}

.language-selection{
  display: flex;
  align-items:center;
}
.language-button {
  color: rgba(116, 153, 63, 1);
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
  line-height: 150%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
}
.selected{
  color: rgb(65, 94, 25);
  font-size: 18px;
}

@media (max-width: 768px) {
  .iconGME{
    padding-top: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
  }
  .rectangle{
    padding-top: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
  }
}
