
/* Impressum */


.impressum {
  padding-left: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  gap: 5px;
  width: 90%;
  padding-right: 20%;
  padding-top: 32px;
  padding-bottom: 32px;
  /* height: auto; */
  box-sizing: border-box;
  /* background-color: rgba(248, 249, 250, 1); */
}
.impressum-intro {
  color: rgba(116, 153, 63, 1);
  font-size: 48px;
  line-height: 117%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  align-items:flex-start;
}



.primary-button-impre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
  box-sizing: border-box;
  text-decoration: none;

  
}
.frame-41 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 12px 25px;
  border-radius: 105px;
  box-sizing: border-box;
  background-color: rgba(116, 153, 63, 1);
  text-decoration: none;
}

@media (max-width: 768px) {
  .impressum{
    padding-left: 24px;
    padding-right: 24px;
  }
  .primary-button{
    justify-content: center;
  }
  
}
