.secondary-button12 {
  display: block ruby;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 100%;
  box-sizing: border-box;
}
.frame-14 {
  display: block ruby;
  flex-direction: row;


  gap: 14px;
  width: 100%;
  height: 100%;
  padding: 12px 25px;
  border-radius: 105px;
  box-sizing: border-box;
}
.give-us-feedback {
  color: rgba(116, 153, 63, 1);
  font-size: 16px;
  line-height: 114%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}