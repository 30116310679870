.registration-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vh;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color:#f8f9fa;
  overflow: hidden;
  padding-left: 27%;
  padding-right: 27%;
}
.registation-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.small-title {
  color: rgba(78, 103, 42, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
}
.title {
  color: rgba(78, 103, 42, 1);
  font-size: 32px;
  line-height: 119%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
}
.you-can-test {
  color: rgba(1,1,1, 1);
  font-size: 16px;
  line-height: 150%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
}
.underlined{
  text-decoration: underline;
}

.form-groups{
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 3%;
}
.contatiner-div{
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 8px;
}
.name {
  color: rgba(116, 153, 63, 1);
  font-size: 16px;
  line-height: 150%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  /* min-width: 15vw ; */
}
.group-16 {
  height: 40px;
  border: 1px solid rgba(116, 153, 63, 1);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  width: 20vw;
}
.rows-and-cols{
  display: flex;
  /* gap: 8px; */
  /* margin-right: 15px; */
  gap: 8px;
}
.invalid-feedback {
  color: red;
}
.group-17{
  height: 40px;
  border: 1px solid rgba(116, 153, 63, 1);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  width: 12vw;
}
#clickboxes {
  display: flex;
  flex-direction: column;
}
.checkbox-walk {
  display: flex;
  align-items: center;
  align-content: baseline;
  margin-bottom: 10px;
}
.wcpa_form_outer .wcpa_form_item .wcpa_checkbox input[type=checkbox]{
  opacity:1;
  position: relative;
  width:auto;
  }
  .wcpa_form_outer .wcpa_form_item .wcpa_checkbox .wcpa_check{
  display:none;
  }
  .wcpa_form_outer .wcpa_form_item .wcpa_checkbox label{
  display:inline;
  }
.checkbox-walk input[type="checkbox"] {
  border-radius: 0.15em;
  margin-right: 20px;
  border: 0.15em solid #007a7e;
  outline: none;
  cursor: pointer;
}

.checkbox-walk a {
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex; 
  align-items: center; 
}
.sized-checkbox{
  width: 18px;
}
.revoke{
  display: flex;
  align-items: 'center';
}
.terms-div{
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.primary-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.frame-41 {
  display: flex;
  border-radius: 105px;
  box-sizing: border-box;
 
}
.frame-41.selected{
  background-color: rgba(116, 153, 63, 1);
}
.frame-41.not-selected{
  background-color: rgba(200, 200, 200);
}
.click-area-buttons {
  padding-top: 5%;
  padding-left: 0%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 32px;
}

input[type='checkbox'] {
  accent-color:rgba(186,218,85);
}
@media (max-width: 767px) {
  .registration-form {
    padding-left: 24px;
    padding-right: 24px;
  }
  .form-groups{
    padding-top: 5%;
  }
  .group-16{
    width: 85vw;
  }
  .rows-and-cols{
    display: grid;
    gap: 8px;
    
  }
  .group-17{
    width: 85vw;
  }
  .you-can-test{
    font-size: 16px;
  }

  .captcha-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  /* .label-checkboxes-bottom{
    font-size: 16px;
  } */
  .click-area-buttons{
    padding-top: 34px;
    padding-bottom: 64px;
    padding-left: 25%; 
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .group-16{
    width: 60%
  }
  .you-can-test{
    font-size: 18px;
  }
  
}