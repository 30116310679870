.terms-conditions{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  padding-left: 20%;
  width: 80%;
  /* height: 80vw; */
  box-sizing: border-box;
  font-family: IBM Plex Sans, sans-serif;
}
.main-text{
  color: rgba(116, 153, 63, 1);
  font-size: 32px;
  line-height: 119%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  /* padding-left: 20%; */
}
.terms-text{
  color: rgba(1, 1, 1, 1);
  padding-left: 0;
  width: 80%;
}
.cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 1%;
  gap: 19px;
}




@media (max-width: 768px) {
  .terms-conditions{
    width: 100%;
    padding-left: 18px ;
    padding-right: 18px;
  }
  .main-text{
    font-size: 24px;
  }
  .terms-text{
    width: 100%;
    padding: 0;
    font-size: 12px;
  }
  .cta{
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

