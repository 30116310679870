.pressStyle{
  /* style={{marginTop:'0px', backgroundColor:'white', color: 'black', paddingLeft:'20%', paddingRight:'20%'}}; */
  margin-top: 0px;
  background-color: white;
  color: black;
  padding-left: 20vw;
  padding-right: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 767px) {
  .pressStyle{
    padding-left: 10vw;
    padding-right: 10vw;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .pressStyle{
    padding-left: 15vw;
    padding-right: 15vw;
  }
}