.datenschutz{
  font-size: 14px;
  padding-left: 5%;
  padding-right: 5%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  padding-bottom: 32px;
  padding-top: 32px;
  width: 60%;
}
h1{
  color: #4E672A;
}
h2{
  font-size: 600;
  color: #4E672A;
}
h3{
  color: #4E672A;
}
h4{
  color: #4E672A;
}
p {
  margin-bottom: 16px;
  font-weight: 400;
}
.primary-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.frame-41 {
  display: flex;
  border-radius: 105px;
  box-sizing: border-box;
  background-color: rgba(116, 153, 63, 1);
}
@media (max-width: 1000px) {
  .primary-button{
    justify-content: center;
  }
  
}