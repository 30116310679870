
.section-feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-top: 64px;
  padding-bottom: 64px;
  /* width: 100%; */
  box-sizing: border-box;
  position: relative;
  background-color: #f8f9fa;
  padding-right: 0px;
  

}
.overall-feedback-text{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tell-us-more{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.icons-location {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
  /* left: 43%; */
  position: relative;
}
.icon{
  width: 5vw;
  border-radius: 50%;
  cursor: pointer;
}

.icon.sad {
  background-color: rgb(255,114,70); 
}

.icon.meh {
  background-color: rgb(218,225,20); 
}

.icon.happy {
  background-color: rgb(168,218,85); 
}
.icons-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rate-text{
  font-weight: 700;
  font-family: IBM Plex Sans, sans-serif;
}
.captcha-container{
  align-items: center;
  flex-direction: column;
}

.your-opinion-matters-ho {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 100%; */
  /* height: 100%; */
  color: rgba(78, 103, 42, 1);
  font-size: 48px;
  line-height: 117%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  text-align: center;
}
 
.how-satisfied{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 100%; */
  /* height: 100%; */
  color: rgba(116, 153, 63, 1);
  font-size: 48px;
  line-height: 117%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  text-align: center;
  padding-bottom: 40px;

}


/* .comments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

} */
.label-text {
  color: rgba(116, 153, 63, 1);
  font-size: 16px;
  line-height: 136%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  padding-bottom: 4px;
  align-items: flex-start;
}

.feedback-form {
  padding: 10px 10px 10px 18px;
  border: 1px solid rgba(116, 153, 63, 1);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  /* color: rgba(116, 153, 63, 1); */
  font-size: 18px;
  line-height: 136%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  width: 50vw;
  min-height: 20vh;
  
}

/* .textarea-wrapper {
  flex: 1;
} */

.textarea {
  border: none !important;
  /* border-color: transparent; */
  background-color: transparent;
  resize: vertical; 
  font-size: 18px;
  line-height: 136%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
}
.textarea:focus,
.textarea:active {
  outline: none;
  border: none;
}


.frame-31 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* gap: 16px; */
  width: fit-content;
  box-sizing: border-box;
}

/* .primary-button-feedback {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  box-sizing: border-box;
} */
.frame-41 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 12px 25px;
  border-radius: 105px;
  box-sizing: border-box;
  background-color: rgba(116, 153, 63, 1);
}
.selected{
  font-size: 16px;
}
.not-selected{
  font-size: 16px;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
}
.send-feedback-feedback {
  color: rgba(248, 249, 250, 1);
  font-size: 16px;
  line-height: 114%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  background-color: transparent; 
  border: none;
  cursor: pointer;

}

.small-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(116, 153, 63, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: IBM Plex Sans, monospace;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 1000px) {
  .icon{
    width: 20vw;
  }
  .your-opinion-matters-ho{
    font-size: 32px;
  }
  .how-satisfied{
    font-size: 32px;
    
  }
  .overall-feedback-text{
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
  .feedback-form{
    width: 100%;
  }
  .tell-us-more{
    padding-left: 24px;
    padding-right: 24px;
    width: 80%;
  }
  .captcha-container{
    font-size: 16px;

  }

}