.where-to-find {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  box-sizing: border-box;
  background-color: rgba(116, 153, 63, 1);
  padding-bottom: 64px;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 64px;
}
.where-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(253, 252, 252);
  font-size: 48px;
  line-height: 117%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  text-align: center;
  /* padding-top: 5%; */

}
.image-battery{
  gap:24px;
  width: 32vw;
}
.image-container{
  width: 20vw;
}
.map-image {
  display: flex;
  flex-direction: column;

}


.address-link {
  display: flex;
  align-items: center;
  gap: 10px; 
  font-weight: 500;

}

.address-link .icon-location {
  width: 20px; 
}
.explanation{
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 32vw;
  
}
.address-text{
  padding-top: 8px;
  color: rgb(253, 252, 252);
}

@media (max-width: 768px) {
  .map-image {
    /* Override flexbox properties for mobile */
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .image-battery{
    width: 90vw;
  }
  .map-image .image-battery {
    padding-right: 22px;
    padding-left: 22px; 
    margin-right: 0%; 
  }
  .where-text{
    width: 95vw;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 10px;
  }
  .explanation{
    width: 80vw;
  }

}