/* body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  src: url(./fonts/IBMPlexSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  src: url(./fonts/IBMPlexSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  src: url(./fonts/IBMPlexSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  src: url(./fonts/IBMPlexSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 800;
  src: url(./fonts/IBMPlexSans-SemiBold.ttf) format('truetype');
}
