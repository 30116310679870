.primary-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  /* gap: 10px; */
  /* height: 90%; */
  box-sizing: border-box;
}
.frame-41 {
  display: flex;
  border-radius: 105px;
  box-sizing: border-box;
  background-color: rgba(116, 153, 63, 1);
}
.become-atest-user {
  color: rgba(248, 249, 250, 1);
  font-size: 16px;
  line-height: 114%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  background-color: transparent; 
  border: none; 
  cursor: pointer;

  /* padding: 0;  */
}
@media (max-width: 768px) {
  .primary-button{
    justify-content: center;
  }
  
}