.status-bar-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 56px;
}
.status-bar-text.true{
  background-color: rgba(186, 218, 85, 1);
}
.status-bar-text.false{
  background-color: rgba(255, 114, 70, 1);
}
.text-status {
  color: rgba(31, 31, 31, 1);
  font-size: 16px;
  line-height: 150%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  margin: 12px;

}

.usmile-squint-wink-alt {
  height: 90%;
  margin: 15px;
}
.div-status{
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 768px) {
  .div-status{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

}