.custom-datepicker {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-left: 8px;
  height: 40px;
}

.custom-datepicker .react-datepicker__triangle {
  display: none;
}

.custom-datepicker .react-datepicker__header {
  background-color: transparent;
  border: none;
}

.custom-datepicker .react-datepicker__current-month {
  color: rgba(31, 31, 31, 1);
  font-size: 16px;
  line-height: 150%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
}

.custom-datepicker .react-datepicker__day-names {
  background-color: transparent;
}

.custom-datepicker .react-datepicker__day {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(31, 31, 31, 1);
  font-size: 16px;
  line-height: 150%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
}

.custom-datepicker .react-datepicker__day--selected {
  background-color: rgba(116, 153, 63, 1);
  color: rgba(255, 255, 255, 1);
}
