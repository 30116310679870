.main-container-desktop{
  height: 100vh;
  display: flex;
  flex-direction: column;
  
}
.scroll-button{
  position: fixed;
  bottom: 3.8%;
  right: 2%;
  background-color:black;
  color: #fff;
  padding: 10px 25px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  z-index: 5;
  font-size: 14px;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.header-desktop{
  position: relative;
}
.status-bar-desktop{
  position: relative;
}
.hero-desktop{
  flex: 1;
}

.how {
position: relative;;
}
.availability-desktop{
  position: relative;
}
.where-desktop{
  position: relative;
}
.feedback-section-desktop{
  position: relative;
}
.faq-desktop{
  position: relative;
}

.link-footer-desktop{
  font-size: 16px;
  line-height: 150%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  color: rgba(116, 153, 63, 1);
}
