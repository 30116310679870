.registration-form-waiting-for-confirmation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 10%;
  padding: 64px 24px;
  box-sizing: border-box;
  background-color: rgba(248, 249, 250, 1);
}
.group-26 {
  position: relative;
  /* width: 782.06px; */
  height: 10%;
  box-sizing: border-box;
}
.title {
  /* position: absolute; */
  top: 56.86%;
  bottom: -31.37%;
  left: 0%;
  right: 50.27%;
  color: rgba(116, 153, 63, 1);
  font-size: 32px;
  line-height: 119%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
}

.small-title {
  /* position: absolute; */
  top: 0px;
  left: 0px;
  color: rgba(78, 103, 42, 1);
  font-size: 16px;
  line-height: 16px;
  font-family: IBM Plex Sans, monospace;
  font-weight: 500;
}

.we-sent-you-an-email-plea {
  color: rgba(78, 103, 42, 1);
  font-size: 16px;
  line-height: 150%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
}


.frame-41 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 12px 25px 12px 24px;
  border-radius: 105px;
  box-sizing: border-box;
  background-color: rgba(116, 153, 63, 1);
}
.done {
  color: rgba(248, 249, 250, 1);
  font-size: 14px;
  line-height: 114%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  background-color: transparent; 
  border: none; 
  cursor: pointer;
}
