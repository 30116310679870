.hero-main{
  position: relative;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  height: inherit;
  width: 100%; */
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 5%;
  padding-left: 55%;
}



.welcome-join-our-missio {
  /* text-align: right; */
  color: rgb(1, 1, 1);
  font-size: 48px;
  line-height: 117%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 700;
  /* word-wrap: break-word; */
  /* position: absolute; */
}


.by-becoming-atest-user-y {
  padding-top: 2%;
  color: rgb(1, 1, 1);;
  font-size: 30px;
  line-height: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  width: 100%;
  z-index: 1;

}

.click-area-buttons-hero {
  padding-top: 1%;
  padding-right: 50%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.image-container-hero{
  flex: 1;
}
.image-container-hero img{
  width: 100%;
}
.background-image,.backgroung-ipad {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../../public/Images/heroimage1505x610.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  background-position: center center;
}



.label1 {
  color: #74993f;
  position: relative;
  font-weight: 600;
  
}
.label2 {
  position: relative;
  color: #4e672a;
  font-weight: 600;
  
}
.label3 {
  position: relative;
  color: #74993f;
  font-weight: 600;
  
}
.textBlock {
  color: rgb(1, 1, 1);;
  font-size: 18px;
  line-height: 24px;
  
  
}
.list {
  font-size: 18px;
  padding-top: 0%;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 10;

}
.list ::marker {
  color: rgb(1, 1, 1);
  font-size: 16px;
  line-height: 24px;
  
}
.textBlock2 {
  margin-top: 12px;
}
.labelWrapper2 {
  font-size: 16px;
  
  flex-shrink: 1;
}
.label4 {
  color: #4e672a;
  font-size: 16px;
  line-height: 24px;
  
}
.textBlock3 {
  margin-top: 12px;
  color: #4e672a;
  font-size: 16px;
  line-height: 24px;
  
}
.textBlock4 {
  margin-top: 12px;
  color: #4e672a;
  font-size: 16px;
  line-height: 24px;

}
.textBlock5 {
  margin-top: 12px;
}
.labelWrapper3 {
  font-size: 20px;

  flex-shrink: 1;
}
.label5 {
  color: #4e672a;
  font-size: 20px;
  line-height: 24px;

}
.mobile-image {
  position: relative;
}

.mobile-image img {
  width: auto;
  height: 250px;
}

@media (max-width: 767px) {
  .hero {
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .welcome-join-our-missio{
    font-size: 32px;
    line-height: 120%;
    padding-top: 0%;
  }
  .click-area-buttons-hero{
    padding-top: 34px;
    padding-bottom: 64px;
    padding-right: 0%; 
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .background-image{
    height: 350px;
    z-index: 0;
  }
  .mobile-image img {
    height: auto;
    width: 100%;
  }
  
}
@media (min-width: 768px) and (max-width: 1100px) {
  .label1{
    font-size: 26px;
    line-height: 60%;
  }
  .label2{
    font-size: 26px;
    line-height: 60%;
  }
  .welcome-join-our-missio{
    line-height: 60%;
    padding-top: 2%;
    /* padding-left: ; */
  }
  .hero{
    padding-right: 1%;
    padding-left: 50%;
    padding-top: 2%;
    padding-bottom: 2%;

  }
  .list{
    font-size: 16px;
    line-height: 120%;
    /* padding-top: 5%; */
  }
  .textBlock{
    font-size: 16px;
  }
  .click-area-buttons-hero{
    padding-right: 0;
  }
  .by-becoming-atest-user-y{
    padding-top: 5%;
    padding-right: 5%;
  }
  
}