.flex-container-how {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 0%;
    margin-right: 0%;
    
    /* margin: 32px 64px; */
  }

.flex-container1 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* align-content: center; */
  }
.ML40 {margin-left: auto; margin-right:auto} 

.ML20 {margin-left: 20%; margin-right: 20%;}





.midgreen{ background-color: #BADA55;}
.darkgreen{ color: #4E672A; }

.centertext {
  text-align: center;
} 



.howbox{ 
  text-align: center;
  background-color: #BADA55;
  height: 460px; 
  margin: 10px ;
  width: 23%;

}

.howboxMobile{ 
  height: 460px;
  padding: 20px;
  width: 80%;
  align-items: center;
  text-align: center;}

.mobileArrow {
width: 50px;
height: 50px;}  



.whitecircle {

background-color: #F8F9FA;
width: 64px;
height: 64px;
border-radius: 50%;
/* position: relative;
left: 42%;
right: 42%;  */

display: block;
margin-left: auto;
margin-right: auto;
margin-top: 56px;
margin-bottom: 56px;
/* margin-left: 43%;  */
}

.circlenum{padding: 34%;}

.MobileTitle{
  color: #4E672A;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  align-items: center;
  padding-bottom: 24px;
}
.MobileSquareTitle{
  color: #4E672A;
  font-family: IBM Plex Sans , sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20.8px;
  align-items: center;

}

.MobileSquareText{
  font-family: IBM Plex Sans , sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #0b0b0b;
}

.MobileTOS{
  font-family: IBM Plex Sans , sans-serif;
  color: #1F1F1F;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  align-items: center;
  text-align: center;
}

.DesktopTitle{
  color: #4E672A;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  align-items: center;
  padding-bottom: 32px;
}
.DesktopSquareTitle{
  color: #4E672A;
  font-family: IBM Plex Sans , sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 20.8px;
  align-items: center;
}
.DesktopSquareText{
  color: #0b0b0b;
  font-family: IBM Plex Sans , sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  padding: 24px;
  padding-top: 8px;
}
.DesktopTOS{
  font-family: IBM Plex Sans , sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  align-items: center;
  text-align: center;
  color: #1F1F1F;
}

.noHighLight {
  user-select: none;
}


@media  (max-width: 1000px) {

  .flex-container1{
    margin: 24px 24px;
    /* height: 400px; */
  }
}