/* available main theme */
.available-check {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  height: max-content;
  box-sizing: border-box;
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: 36px;
  padding-right: 36px;
}
.available-check.false{
  background-color: rgba(255, 114, 70, 1);
}
.available-check.true{
  background-color: #BADA55;
}
/* charger info */
.ev-charger-thomas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  text-align: center;
  font-size: 32px;
  color: rgb(252, 252, 252);
  line-height: 117%;
}
.ev-charger-thomas.true{
  color: rgba(113, 149, 62, 1);
}
.ev-charger-thomas.false{
  color: rgba(248, 249, 250, 1);
}

.availability-check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: fit-content;
  box-sizing: border-box;
}
.g2ENAVfont{  
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 117%;
  color: #0b0b0b;
  text-align: center;
}
.group-check-available {
  border: 3px solid rgba(31, 31, 31, 1);
  box-sizing: border-box;
  background-color: rgba(248, 249, 250, 1);
  overflow: hidden;
  align-content: center;
}
.text-next-available-check {
  padding-top: 28px;
  padding-bottom: 28px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(31, 31, 31, 1);
  font-size: 32px;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent; 
  border: none; 
  white-space: nowrap;
  overflow: hidden;
  line-height: 117%;
}







.availablePlug{
  margin-left: 20px;
  margin-right: 20px;

  color: #4E672A;
}
.occupiedPlug{
  margin-left: 20px;
  margin-right: 20px;
  color: #FF7246;

}

.plug{
  text-align: center;
  background-color: #FFFFFF;
  width: 45%;
  height: fit-content;
  margin-left: 20px;
  margin-right: 20px;
  padding: 12px;
  border-radius: 5%;
}
.kwhCircle{
  border:4px #1F1F1F solid ;    
  height:256px;
  width: 256px;
  border-radius:50%;
  background-color: #F8F9FA;
  display: flex;
  justify-content: center;
  align-items: center; 
}
.kwhCircle2{
  border:4px #1F1F1F solid ;    
  height:286px;
  width: 286px;
  border-radius:50%;
  /* background-color: #74993F; */
  display: flex;
  justify-content: center;
  align-items: center; 
}
.kwhnum {
  font-weight: 600;
  font-size: 44px;
  line-height: 57.2px;
  text-align: center;
  color: #1F1F1F;
  font-family: IBM Plex Sans, condensed;

}
.rAvailableAtfont{
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 117%;
  text-align: center;
}
.g1CINfontGreen{
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 117%;
  color: #4E672A;
}
.g3Asteriskfont{
  color: #4E672A;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.g4PlugsAvfont{
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 117%;
  text-align: center;
  color: #0b0b0b;
}
.flex-container-availability {
  display: flex;
}

@media (max-width: 768px)  {
  .available-check{
    padding-left: 24px;
    padding-right: 24px;
  }

  .ev-charger-thomas {  
    font-size: 32px;
    line-height: 117%;
  }

  .availability-check{
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }

  .g2ENAVfont{  
    font-weight: 600;
    font-size: 16px;
    line-height: 117%;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
