.flex-container {
    display: flex;

}

.email_and_linkedin_font {
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.links{
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #4E672A;
    align-items: center;
    }
.linksng{
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    }
.aligned {
    text-align: center;
    justify-content: center;
}
.image1{
    height: 20px;
    width: 20px;
}
.overall-footer{

    padding-top: 24px;
    padding-bottom: 24px;
    background-color:rgba(248, 249, 250, 1);
}
.line-break{
    line-height: 25px;
}