.faq-section{
  padding-top: 64px;
  padding-bottom: 64px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  /* box-sizing: border-box; */
}
.faq-text{
  font-size: 48px;
  line-height: 134%;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  text-align: center;
  color: rgba(116, 153, 63, 1);
  padding-bottom: 24px;
}
.font1 {
  font-family: IBM Plex Sans, sans-serif;
  /* font-weight: 600; */
}

.thickletters { font-weight: 600;}

.fontsizeFAQ { 
  font-size: 32px;
  line-height: 134%;
  text-align: center;
 }

.fontsizeSC { 
  font-size: 18px;
  line-height: 134%; 
  font-weight: 600;
}
.fontsizeQnA { 
  font-size: 16px;
  line-height: 148%; 
}



/* .rightArrow {
right: 20px;} */

.somepadding {
margin-right: 8px;
}

.flex-container {
  display: flex;
}

.BigGreenLetters {
  color:rgba(116, 153, 63, 1)
  /* color:#74993F; */

}

.DarkBigGreenLetters {
  color: #4E672A;
  user-select: none;

}
.ML40-faq{
  font-size: 40px;
  margin-left: 0;
  margin-right: 0;
}
.ML20-faq {margin-left: 20%; margin-right: 20%;}


.categorybox {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.categories-faq{
  width: 100%;
}


.overflowingtext {
  overflow-wrap: break-word;
}
.answerboundary { 
  /* width: 40vw;  */
  margin-bottom: 12px;
}
.questionBarrier {  
  /* width: 45vw;  */
  height:1px ; 
  background-color: grey; 
  margin-bottom: 24px;
  margin-top: 24px;
  background-color: #d0d0d0;
}
.subcaticon{ 
  height: 24px; 
  width: 24px; 
  color: rgba(116, 153, 63, 1);
}

@media (max-width: 768px) {
  .ML20-faq {margin-left: 24px; margin-right: 24px;}
  /* .ML40 {margin-left: 40%; margin-right: 40%;}  */
  .answerboundary { 
    /* width: 100vw;  */
    padding-left: 12px;
    padding-right: 12px;
  }
  .questionBarrier{
    /* width: 100%; */
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .categories-faq{
    margin-left:0;
    margin-right: 0;
    box-sizing:unset;
    /* width: 90vw; */
  }
  .faq-section{
    padding-left: 12px;
    padding-right: 12px;
  }
}